<template>
  <div class="header">
    <!--头部-->
    <div class="pc content">
      <img class="logo logo1" src="../assets/img/logo1.png" @click="goPage('/')"/>
      <img class="logo logo2" src="../assets/img/logo2.png" @click="goPage('/')"/>
      <a style="align-self: flex-end;margin-left:10px;" href="https://blog.naver.com/onnuriitspc" target="_blank"><img class="blog" src="../assets/img/blog.png"/></a>
      <div class="menu">
        <a>
          기관소개
          <div>
            <a @click="goPage('about1')">인사말</a>
            <a @click="goPage('about2')">국제영업비밀보호</a>
            <a @click="goPage('about3')">관련 법령</a>
            <a @click="goPage('about4')">주요 침해행위</a>
          </div>
        </a>
        <a @click="goPage('notice')">기관소식</a>
        <a>
          서비스 이용
          <div>
            <a @click="goLink('service')">보관의뢰</a>
            <a @click="goLink('service?conType=1')">대표적 정보</a>
            <!-- <a @click="goLink('service?conType=2')">이용 단가표</a> -->
            <a @click="goLink('service?conType=3')">이용 안내</a>
          </div>
        </a>
        <a>
          FAQ
          <div>
            <a @click="goLink('faq?showType=-1')">자주하는 질문</a>
            <a @click="goLink('faq?showType=1')">이용절차</a>
          </div>
        </a>
        <a>
          문의하기
          <div>
            <a @click="goLink('contactUs?conType=2')">Contact us</a>
            <a @click="goLink('contactUs?conType=1')">찾아 오시는 길</a>
          </div>
        </a>
      </div>
      <div class="header_right">
        <div class="before-login" v-if="!isLogin" style="align-items: center;">
          <a class="my-page" @click="goPage('login')">로그인</a>
          <div style="border-right:1px solid #01388B;width:6px;height:20px;margin-right:6px;"></div>
          <a class="my-page" @click="goPage('register')">회원가입</a>
        </div>
        <div class="after-login" v-if="isLogin">
          <div class="menu">
            <span @click="goLink('upload')">원본파일업로드</span>
            <span @click="goLink('myServiceCert')">증명서 발급</span>
          </div>
          <div class="my-page" @click="goPage('myInformation')">마이페이지</div>
          <img @click="exitLogin" src="../assets/img/exit_2.png"/>
        </div>
      </div>
    </div>
    <div class="headerMobile mobile">
      <div class="fixed">
        <div class="menu" @click="isshow = true">
          <img style="margin-left:10px;" src="../assets/img/menu.png"/>
        </div>
        <img class="logo" @click="goPage('/')" src="../assets/img2/mobile_logo.png"/>
        <img @click="exitLogin" style="margin:0 25px 0 57px;" v-if="isLogin" src="../assets/img/exit_2.png"/>
        <div class="my-page" v-if="!isLogin" @click="goPage('login')">로그인</div>
      </div>
      <div class="hided" v-if="isshow">
        <div class="top">
          <img class="img" src="../assets/img/x_1.png" @click="isshow = false"/>
          <div v-if="isLogin" style="padding-bottom:30px;display: flex;flex-direction: column;align-items: center;margin-top:-46px;">
            <span style="margin-bottom:30px;font-weight: bold;font-size:18px;">{{userId}}</span>
            <div style="display: flex;justify-content: space-between;width:100%;">
              <div @click="goPage('myInformation')" style="display: flex;flex-direction: column;align-items: center;">
                <img style="width:24px;margin-bottom: 10px;" src="../assets/img2/account_circle.png"/>
                정보관리
              </div>
              <div @click="goPage('myFile')" style="display: flex;flex-direction: column;align-items: center;">
                <img style="width:24px;margin-bottom: 10px;" src="../assets/img2/quick_reference_all.png"/>
                파일관리
              </div>
              <div @click="goPage('myCert')" style="display: flex;flex-direction: column;align-items: center;">
                <img style="width:24px;margin-bottom: 10px;" src="../assets/img2/description.png"/>
                신청내역
              </div>
              <div @click="goPage('myTransaction')" style="display: flex;flex-direction: column;align-items: center;">
                <img style="width:24px;margin-bottom: 10px;" src="../assets/img2/credit_card.png"/>
                결제/환불
              </div>
            </div>
          </div>
          <div class="link">
            <span @click="menuType = (menuType == 1 ? 0 : 1)" :class="menuType == 1?'span':''">기관소개</span>
            <div v-if="menuType == 1">
              <a @click="goPage('about1')">인사말</a>
              <a @click="goPage('about2')">국제영업비밀보호</a>
              <a @click="goPage('about3')">관련 법령</a>
              <a @click="goPage('about4')">주요 침해행위</a>
            </div>
          </div>
          <div class="link" @click="goPage('notice')">기관소식</div>
          <div class="link" @click="goPage('service')">서비스 이용</div>
          <div class="link">
            <span @click="menuType = (menuType == 2 ? 0 : 2)" :class="menuType == 2?'span':''">FAQ</span>
            <div v-if="menuType == 2">
              <a @click="goPage('faq?showType=0')">자주하는 질문</a>
              <a @click="goPage('faq?showType=1')">이용절차</a>
            </div>
          </div>
          <div class="link" @click="goPage('contactUs')">문의하기</div>
        </div>
        <div class="copyright">
          Copyright © {{year}} ONNURI Int'l TS Protection Center Corp.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getAction,postAction } from '@/network/manage'
  import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'
  import Vue from 'vue'

  export default {
    name: 'Header',
    data() {
      return {
        isshow:false,
        menuType:0,
        userId:'',
        year:1000
      }
    },
    computed: {
      ...mapGetters([
        'token'
      ]),
      isLogin() {
        return Vue.ls.get(ACCESS_TOKEN) !== null && Vue.ls.get(ACCESS_TOKEN) !== ''
      },
    },
    created(){
      this.userId = Vue.ls.get("userId");
      this.year = new Date().getFullYear();
    },
    mounted() {

    },
    methods: {
      goPage(url){
        this.$router.push({path:url});
        this.isshow = false;
      },
      goLink(url){
        window.location.href = url
      },
      exitLogin(){
        postAction('/user/userInformation/loginOut').then((res) => {
          Vue.ls.remove(ACCESS_TOKEN);
          Vue.ls.remove(CERT_LOGIN);
          window.location.href='/'
        }).catch(res => {})
      }
    }
  }
</script>

<style scoped>
  .header{position: fixed;z-index:1000;top:0;left:0;width:100%;display: flex;justify-content: space-between;align-items: center;background-color: #ffffff;}
  .content{margin:auto;display: flex;align-items: center;}
  .logo{height:54px;cursor: pointer;flex-shrink: 0;}
  .menu{display: flex;width: 100%;align-items: center;justify-content: center;}
  .menu a{margin:0 10px;padding:0 10px;height:80px;line-height:80px;font-family: 'SF UI Display';font-size:18px;font-weight: bold;cursor: pointer;display: flex;flex-direction: column;}
  .menu a div{display:none;}
  .menu a:hover{color:#01388B;border-bottom:3px solid #01388B;}
  .menu a:hover div{display: flex;position: absolute;padding:5px;margin-left:-10px;background-color: #ffffff;flex-direction: column;top:80px;}
  .menu a:hover div a{color:rgba(95, 95, 95, 1);line-height:46px !important;height:46px;margin:0 0;font-weight: normal;}
  .menu a:hover div a:hover{font-weight: bold;color:#000000;border-bottom-width:0;}
  .header_right{flex-shrink: 0;}
  .header_right .before-login{display:flex;}
  .header_right .after-login{display: flex;align-items: center;}
  .header_right .after-login .menu{display: flex;}
  .header_right .after-login .menu span{cursor: pointer;background-color: #EAFAF6;color:#01388B;padding-left:46px;background-position: center left 14px;background-repeat: no-repeat;background-size: auto 17px;width:160px;height:38px;display: flex;align-items: center;margin:0 5px;border-radius: 2px;font-size:15px;font-family: 'Noto Sans CJK JP';}
  .header_right .after-login .menu span:nth-child(1){background-image: url(../assets/img/upload.png);}
  .header_right .after-login .menu span:nth-child(2){background-image: url(../assets/img/list_1.png);}
  .header_right .my-page{cursor: pointer;color:#ffffff;font-size: 16px;font-family: 'SF UI Display';height:24px;width:95px;background-color: #01388B;border-radius: 12px;margin:0 10px;display: flex;align-items: center;justify-content: center;flex-shrink: 0;}
  .header_right .after-login img{width:20px;cursor: pointer;}
  @media (max-width: 800px) {
  	.content{width:100%;}
    .pc{display:none;}
    .headerMobile .fixed{z-index:1000;background-color: #ffffff;position: fixed;top:0;left:0;width:100%;height:60px;display:flex;justify-content: space-between;align-items: center;}
    .headerMobile .fixed .menu{margin:16px;width:70px;display:flex;align-items: center;justify-content: flex-start;}
    .headerMobile .fixed .logo{height:30px;}
    .headerMobile .fixed .my-page{color:#ffffff;font-size: 16px;font-family: 'SF UI Display';height:24px;width:70px;background-color: #01388B;border-radius: 12px;display: flex;align-items: center;justify-content: center;margin:16px;}
    .headerMobile .fixed .log-in{font-size: 16px;font-family: 'SF UI Display';height:24px;width:70px;color: #01388B;display: flex;align-items: center;justify-content: center;margin:16px 5px 16px 27px;font-weight: bold;}
    .headerMobile .hided{z-index:1000;top:60px;left:0;width:100vw;height:100vh;position: fixed;background: rgba(255,255,255,0.9);padding:20px 40px;}
    .headerMobile .hided .top{text-align: right;}
    .headerMobile .hided .top .img{height:17px;margin-bottom:20px;}
    .headerMobile .hided .top .link{text-align: left;width:100%;display: flex;flex-direction: column;border-bottom:1px solid #888888;padding:8px 0;margin-bottom:10px;font-family: 'SF UI Display';font-size:18px;font-weight: bold;}
    .headerMobile .hided .top .link span{width:100%;background-image: url(../assets/img/bottom.png);background-repeat: no-repeat;background-position: center right;}
    .headerMobile .hided .top .link .span{background-image: url(../assets/img/bottom_top.png);}
    .headerMobile .hided .top .link div{display: flex;margin-top:10px;border-top:1px solid #888888;flex-direction: column;color:#404040;padding:16px;font-weight: normal;line-height: 2;}
    .headerMobile .hided .top .act{color:#01388B;}
    .headerMobile .hided .top .info{margin:50px 30px;display: flex;justify-content: space-between;align-items: center;}
    .headerMobile .hided .top .info .left{display: flex;flex-direction: column;text-align: left;}
    .headerMobile .hided .top .info .left a{font-family: 'Pretendard';font-size: 24px;font-weight: bold;color: #01388B;}
    .headerMobile .hided .top .info .left span{font-family: 'Pretendard';font-size: 18px;color: #888888;letter-spacing: -1px;}
    .headerMobile .hided .top .info .right{border:1px solid #3B5998;border-radius: 18px;width:50px;height:50px;display: flex;justify-content: center;align-items: center;}
    .headerMobile .hided .top .info .right img{width:26px;}
    .headerMobile .hided .copyright{z-index:1001;color:#4A4A4A;font-family: 'SF UI Display';font-size: 13px;text-align: center;flex-shrink: 0;position: fixed;bottom:0;left:0;width:100vw;height:120px;display: flex;justify-content: center;align-items: center;flex-direction: column;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.content{width:800px;}
    .logo1{display: none;}
    .mobile{display:none;}
    .header{height:80px;}
    .menu a{margin: 0 2px;font-size: 12px;}
    .logo{height:36px;margin-right:6px;}
    .header_right .before-login{font-size:14px;}
    .header_right .before-login a{font-size:14px;}
    .header_right .after-login .menu span{padding-left:28px;background-position: center left 8px;background-size: auto 14px;width:120px;height:30px;margin:0 5px;font-size:13px;}
    .header_right .after-login .my-page{font-size: 12px;height:20px;width:70px;border-radius: 10px;margin:0 5px;display: flex;align-items: center;justify-content: center;}
    .header_right .after-login img{width:16px;}
    .blog{width:26px;margin-bottom:10px;}
  }
  @media(min-width: 1200px){
  	.content{width:1200px;}
    .mobile{display:none;}
    .header{height:80px;}
    .logo2{display: none;}
    .blog{width:40px;margin-bottom:5px;}
  }
</style>
